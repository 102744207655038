html {
  scrollbar-gutter: stable;
}
body {
  overflow-x: hidden;
}

.loading-select {
  background: #ffffff
    url(https://uploads-ssl.webflow.com/6423dc0021de6a2495a22761/6439d21c4eaafcea3ea03cb3_Infinity-1s-200px%20%281%29.svg)
    no-repeat center center;
  background-size: 100px 100px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  transition: none;
}

.chosen-value[disabled] {
  opacity: 0.7;
}
.chosen .chosen-value:focus,
.chosen-value.open {
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
  outline: 0;
  color: #000;
}
#vehicleSelectionForm input::-moz-selection {
  background: #d4af37;
}
#vehicleSelectionForm input::selection {
  background: #d4af37;
}

.input-container img {
  pointer-events: none;
}
.value-list.open {
  overflow-x: hidden;
  text-align: center;
}
.custom-dropdown {
  width: 400px;
}
.year-dropdown,
.model-dropdown,
.engine-dropdown {
  position: absolute;
  /* left: 15px; */
  visibility: hidden;
  flex-grow: 0;
  opacity: 0;
  transition: all 200ms;
}
.fade-in-dropdown {
  position: relative;
  visibility: visible;
  /* left: 0px; */
  flex-grow: 1;
  opacity: 1;
}

.focused-li {
  background-color: #dfddcf;
  font-weight: bold;
}

.left-file-container:hover .left-arrow-container,
.right-file-container:hover .right-arrow-container {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 991px) {
  #vehicleForm,
  .hero-subflex {
    transform: none !important;
    transform-style: unset !important;
  }

  .chosen-value,
  .value-list,
  .custom-dropdown {
    transition: none !important;
  }

  /* .fixed-dropdown {
    position: fixed;
    top: 20%;
    width: 80%;
    max-width: 400px;
    z-index: 950 !important;
  } */
  .fixed-dropdown {
    position: fixed;
    top: 20%;
    width: 80%;
    max-width: 350px;
    z-index: 950 !important;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

#vehicleSelectionForm {
  scroll-margin-top: 250px;
}

/*Sliders */
input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 20px;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /* animate: 0.2s; */
  border-radius: 25px;
  z-index: 1;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /* animate: 0.2s; */
  border-radius: 25px;
  z-index: 1;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /* animate: 0.2s; */
  border-radius: 25px;
  z-index: 1;
}

input[type='range']::-webkit-slider-thumb {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #000 no-repeat center;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);

  cursor: grab;
  -webkit-appearance: none;
  margin-top: -10px;
  transform-origin: center;
  transition: transform 200ms ease-in-out;
}
input[type='range']::-moz-range-thumb {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #000
    /* url('https://uploads-ssl.webflow.com/6423dc0021de6a2495a22761/64c0d84eaa076fe8dee119a5_expand-arrows.png') */
    no-repeat center;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);

  border: none;
  cursor: grab;
  -webkit-appearance: none;
  appearance: none;
  margin-top: -10px;
  transform-origin: center;
  transition: transform 200ms ease-in-out;
}
input[type='range']::-ms-thumb {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #000
    /* url('https://uploads-ssl.webflow.com/6423dc0021de6a2495a22761/64c0d84eaa076fe8dee119a5_expand-arrows.png') */
    no-repeat center;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
  cursor: grab;
  -webkit-appearance: none;
  appearance: none;
  margin-top: -10px;
  transform-origin: center;
  transition: transform 200ms ease-in-out;
}

input[type='range']::-webkit-slider-thumb:hover {
  transform: scale(1.18);
}
input[type='range']::-moz-range-thumb:hover {
  transform: scale(1.18);
}
input[type='range']::-ms-thumb:hover {
  transform: scale(1.18);
}

.input-slider {
  background: #d4af37;
  background: -moz-linear-gradient(90deg, #d4af37 0%, #f7da00 100%);
  background: -webkit-linear-gradient(90deg, #d4af37 0%, #f7da00 100%);
  background: linear-gradient(90deg, #d4af37 0%, #f7da00 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d4af37",endColorstr="#f7da00",GradientType=1);
}
.input-slider::-moz-progress-bar {
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  width: 100%;
  background: linear-gradient(90deg, #e5e5e5 50%, #e5e5e5 50%);
}

.cover {
  position: absolute;
  height: 16px;
  right: -2px;
  bottom: 9px;
  background: #bebebe;
  border-radius: 25px;
  z-index: 0;
  width: 76%;
  float: right;
  pointer-events: none;
}

#systemsContainer {
  scroll-margin-top: 45px;
}

.highlight {
  pointer-events: none;
}

/*Testimonials CSS */
.comments-slider .quote-icon {
  transition: transform 200ms;
}
.comments-slider .comment-container:hover .quote-icon-left {
  transform: translateX(-5px) translateY(-5px);
}
.comments-slider .comment-container:hover .quote-icon-right {
  transform: translateX(5px) translateY(5px) rotate(-180deg);
}
/* End of Testimonail CSS */

.learn-more-at-right {
  left: 100%;
  width: 100%;
  height: auto !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.parallax-highlight {
  pointer-events: none;
}
#testimonialsSection {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .gallery-files-wrapper::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .gallery-files-wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.click-icon-div,
.main-image-gradient {
  pointer-events: none;
}
